exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fund-dynamic-tsx": () => import("./../../../src/pages/fund-dynamic.tsx" /* webpackChunkName: "component---src-pages-fund-dynamic-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-infobreak-chartgenerator-tsx": () => import("./../../../src/templates/infobreak/chartgenerator.tsx" /* webpackChunkName: "component---src-templates-infobreak-chartgenerator-tsx" */),
  "component---src-templates-infobreak-fondmatrix-tsx": () => import("./../../../src/templates/infobreak/fondmatrix.tsx" /* webpackChunkName: "component---src-templates-infobreak-fondmatrix-tsx" */),
  "component---src-templates-infobreak-fondsstrukturauswertungen-tsx": () => import("./../../../src/templates/infobreak/fondsstrukturauswertungen.tsx" /* webpackChunkName: "component---src-templates-infobreak-fondsstrukturauswertungen-tsx" */),
  "component---src-templates-infobreak-performance-kennzahlen-tsx": () => import("./../../../src/templates/infobreak/performance-kennzahlen.tsx" /* webpackChunkName: "component---src-templates-infobreak-performance-kennzahlen-tsx" */)
}

